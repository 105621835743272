.input-group {
	box-shadow: $input-box-shadow;
	border-radius: $input-border-radius;
	transition: $transition-base;

	.form-control {
		box-shadow: none;

		&:not(:first-child) {
			border-left: 0;
			padding-left: 0;
		}
		&:not(:last-child) {
			border-right: 0;
			padding-right: 0;
		}
		&:focus {
			box-shadow: none;
		}
	}
}

.input-group-text {
	transition: $input-transition;
}

.input-group-alternative {
	box-shadow: $input-alternative-box-shadow;
	border: 0;
	transition: box-shadow 0.15s ease;

	.form-control,
	.input-group-text {
		border: 0;
		box-shadow: none;
	}
}

.focused {
	.input-group-alternative {
		box-shadow: $input-focus-alternative-box-shadow !important;
	}
}

// .focus class is applied dinamycally from theme.js

.focused {
	.input-group {
		box-shadow: $input-focus-box-shadow;
	}

	.input-group-text {
		color: $input-group-addon-focus-color;
		background-color: $input-group-addon-focus-bg;
		border-color: $input-group-addon-focus-border-color;
	}

	.form-control {
		border-color: $input-group-addon-focus-border-color;
	}
}
@import "https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700";

// Variables
$bg-body: transparent;

$primary-color: $secondary;
$secondary-color: $primary;

// Typography
$base-font: helvetica, arial, tahoma, verdana;
$base-font-title: "Dosis", arial, tahoma, verdana;

$base-font-color: #726f77;

// Timeline
$timeline-color: $primary-color;

// Mixins and Placeholders
%clearfix {
	&:after,
	&:before {
		content: "";
		display: block;
		width: 100%;
		clear: both;
	}
}

@mixin prefix($prop, $val) {
	@each $prefix in "-webkit-", "-moz-", "-ms-", "" {
		#{$prefix}#{$prop}: $val;
	}
}
*,
*:before,
*:after {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

body,
html {
	height: 100%;
}
body {
	background: $bg-body;
	background-size: cover;
	margin: 0;
	padding: 0;
	font-family: $base-font;
	line-height: 20px;
	font-size: 14px;
	color: $base-font-color;
}



// Buttons
.btn {
	padding: 5px 15px;
	text-decoration: none;
	background: lighten($primary-color, 25%);
	border: 2px solid lighten($primary-color, 10%);
	color: darken($secondary-color, 25%);
	display: inline-block;
	position: relative;
	text-transform: uppercase;
	font-size: 12px;
	@include prefix(border-radius, 5px);
	@include prefix(transition, background 0.3s ease);
	@include prefix(box-shadow, 2px 2px 0 lighten($primary-color, 10%));

	&:hover {
		box-shadow: none;
		top: 2px;
		left: 2px;
		@include prefix(box-shadow, 2px 2px 0 transparent);
	}
}

@media screen and (max-width: 768px) {
	#timeline {
		margin: 30px;
		padding: 0px;
		width: 90%;
		&:before {
			left: 0;
		}

		.timeline-item {
			.timeline-content {
				width: 90%;
				float: right;

				&:before,
				&.right:before {
					left: 10%;
					margin-left: -6px;
					border-left: 0;
					border-right: 7px solid $timeline-color;
				}
			}

			.timeline-icon {
				left: 0;
			}
		}
	}
}
